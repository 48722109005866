import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: async () => import('@app/pages/home.component').then((routes: any) => routes.HomeComponent),
    children: [
      {
        path: '',
        loadComponent: async () => import('@app/pages/products-list/products-list.component').then((routes: any) => routes.ProductsListComponent),
      },
      {
        path: 'product-details/:id',
        loadComponent: async () => import('@app/pages/product-details/product-details.component').then((routes: any) => routes.ProductDetailsComponent),
      },
      {
        path: 'cart',
        loadComponent: async () => import('@app/pages/cart/cart.component').then((routes: any) => routes.CartComponent),
      },
      {
        path: 'customer-data',
        loadComponent: async () => import('./pages/customer-data/customer-data.component').then((m) => m.CustomerDataComponent),
      },
      {
        path: 'order-success',
        loadComponent: async () => import('./pages/order-success/order-success.component').then((m) => m.OrderSuccessComponent),
      },
      {
        path: 'orders',
        loadComponent: async () => import('./pages/orders/orders.component').then((routes: any) => routes.OrdersComponent),
        canActivate: [AuthGuard],
      },
      {
        path: 'login',
        loadComponent: async () => import('./pages/login/login.component').then((routes: any) => routes.LoginComponent),
      },
    ],
  },
  {
    path: 'error',
    loadComponent: async () => import('@app/error/error.component').then((routes: any) => routes.ErrorComponent),
  },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 0],
    }),
  ],
  exports: [RouterModule],
})
export class ROUTES {}
